import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/unsubscribe/:email/:token',
    name: 'unsubscribe-form',
    component: () => import('../views/Unsubscribe.vue')
  },
  {
    path: '/confirm/:email/:token',
    name: 'confirm-subscription',
    component: () => import('../views/Confirmation.vue')
  },
  {
    path: '*',
    name: 'page-not-found',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
