/* eslint-disable */ 
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: 0
  },
  getters: {
    status: state => state.status,
  },
  mutations: {
    set_status(state, payload) {
      state.status = payload;
    }
  },
  actions: {
    async fetch_content({commit}) {},
    async confirm({commit}, subscriber) {
      await axios.get('https://ndc.api.email-content.app.cloud.finnair.com/verify?&email=' + subscriber.email + '&token=' + subscriber.token
        ).then(response => {
          if(!response.data.status) {
            commit('set_status', -1)
          } else {
            commit('set_status', 1)
          }
        })
        .catch(response => commit('set_status', -1))
    },
    async unsubscribe({commit}, subscriber) {
      await axios.get('https://ndc.api.email-content.app.cloud.finnair.com/unsubscribe?&email=' + subscriber.email + '&token=' + subscriber.token
        ).then(response => {
          if(!response.data.status) {
            commit('set_status', -1)
          } else {
            commit('set_status', 1)
          }
        })
        .catch(response => commit('set_status', -1))
    }
  },
})
