<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
  
  body {
    background-image: url("http://image.email.finnair.com/lib/fe92127276640d7c7d/m/6/f8062056-92ec-445d-ac93-ad3fefaa16f1.jpg");
    background-size: cover;
  }

  .theme--light.v-application {
    background: none !important;
  }
</style>
